.homeAboutImgItem {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.alice-carousel {
  .alice-carousel__dots-item:before {
    content: ' '
  }

  .alice-carousel__dots-item {
      margin-bottom: 0rem;
    }

  .alice-carousel__dots-item:not(.__custom) {
      width: 15px;
      height: 15px;
  }

}