//=================================================
// Logo
//=================================================

/*=============================
// Loading
=============================*/
.loading {
    .logo {
        img {
            width: 15.737rem;
        }
    }
}

/*=============================
// Header
=============================*/
.site-header, .modal-header, .site-footer {
    .logo {
        img {
            width: 12rem;
        }
    }
}
